window.addEventListener("DOMContentLoaded", function () {
  Spree.setChFlowCookie();

  document.addEventListener("turbolinks:load", function () {
    console.log("turbolinks:load");
    dataLayer.push({
      event: "optimize.pageload",
      page: {
        url: location.pathname,
        title: document.title,
        referrer: document.referrer,
        hash: location.hash,
      },
    });
  });
});


window.addEventListener('DOMContentLoaded', function() {
  document.addEventListener("turbolinks:load", function() {
    console.log('initTimer')
    Spree.Customization.timer.initTimer();
    Spree.Customization.timer.runTimer();
    Spree.Customization.timer.interval = setInterval(Spree.Customization.timer.runTimer, 1000);
  })
})